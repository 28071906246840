import React, { useState } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Budget() {
  const [formValue, setFormValue] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleInput = (event) => {
    const { name, value } = event.target;
    setFormValue({ ...formValue, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const allInputValue = {
      name: formValue.name,
      email: formValue.email,
      phone: formValue.phone,
      message: formValue.message,
    };
    console.log(allInputValue);

    if (
        formValue.name == "" ||
        formValue.email == "" ||
        formValue.phone == "" ||
        formValue.message == ""
      ) {
        toast.error("Preencha os campos do formulário", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        setTimeout(async () => {
          let res = await fetch(
            "https://projects.jormax.com/api_sendEmail/new_mail_sender.php",
            {
              method: "POST",
              mode: "no-cors",
              headers: { "content-type": "application/json" },
              body: JSON.stringify(allInputValue),
            }
          ).then((response) => {
            console.log(response);
  
            setFormValue({
              name: "",
              company: "",
              email: "",
              phone: "",
              message: "",
            });
            toast.success("Formulário Submetido com Sucesso!", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          });
        }, 500);
      }
  };

  return (
    <div
      name="budget"
      className="w-full font-poppins bg-white h-[650px] flex flex-col"
    >
      <div className="w-full xl:w-[1240px] h-full mx-auto grid md:grid-cols-2 relative">
        <div className=" mx-auto px-4 md:mx-0 text-start md:justify-start md:ml-8 lg:ml-0 mt-16 ">
          <h1 className="text-5xl md:text-6xl font-bold  uppercase mt-1">
            pedido
          </h1>
          <h1 className="text-5xl md:text-6xl font-bold uppercase mt-1">
            de orçamento
          </h1>
          <p className="text-sm w-[350px] leading-6 my-5 font-light">
            Entre em contato connosco pelo formulário, para pedido de orçamento,
            ou pedido de informações.
          </p>
          <p className="text-sm leading-6 pt-2 font-light">
            Se preferir, no rodapé tem os nossos contatos.
          </p>
        </div>

        <div className=" flex h-full w-full justify-start items-center pt-10 lg:pt-[16%]">
          <form
            className="px-8 xs:px-0 xs:w-[350px] mx-auto md:ml-0"
            onSubmit={handleSubmit}
          >
            <div className="border border-slate-600 px-3 py-2 text-xs rounded-sm my-2">
              <input
                type="text"
                name="name"
                value={formValue.name}
                onChange={handleInput}
                placeholder="Nome"
              />
            </div>
            <div className="border border-slate-600 px-3 py-2 text-xs rounded-sm my-2">
              <input
                className="w-full"
                name="email"
                value={formValue.email}
                onChange={handleInput}
                type="email"
                placeholder="E-mail"
              />
            </div>
            <div className="border border-slate-600 px-3 py-2 text-xs rounded-sm my-2">
              <input
                type="tel"
                name="phone"
                value={formValue.phone}
                onChange={handleInput}
                placeholder="Contacto"
              />
            </div>
            <div className="border border-slate-600 px-3 py-2 text-xs rounded-sm my-2">
              <textarea
                className="w-full h-full"
                name="message"
                value={formValue.message}
                onChange={handleInput}
                rows="8"
                placeholder="A sua mensagem"
              ></textarea>
            </div>
            <p className="text-[10px] leading-6 font-light my-1">
              Ao enviar mensagem, declaro que li e aceito os termos e condições.
            </p>
            {/* <button className='text-xs w-[200px] uppercase px-3 py-4 mt-4 text-white bg-black'>enviar mensagem</button> */}
            <button
              className="text-[10px] w-[150px] uppercase px-5 py-3 mt-2 font-light text-white bg-black hover:bg-slate-800 transition-colors"
              type="submit"
            >
              enviar mensagem
            </button>
          </form>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default Budget;
