import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import logoImg from "../assets/LOGOTIPO_final.png";
import ladderImg from "../assets/ladder.png";

function Footer() {
  return (
    <div className="w-full h-[300px] font-poppins bg-black mt-32 md:mt-4 text-white mx-auto grid grid-cols-2 sm:grid-cols-3">
      <div className="hidden w-full h-auto md:flex">
        <img
          className="h-auto absolute ml-10 sm:w-[280px] lg:w-[300px] xl:w-[400px] sm:w-65  sm:-mt-[60px] md:-mt-[60px] lg:-mt-[90px] xl:-mt-[220px]"
          src={ladderImg}
          alt="/"
        />
      </div>
      <div className="h-full w-full flex flex-col justify-center md:justify-end">
        <Link className=" w-40 mx-auto md:w-56 md:my-auto" to="home" smooth={true} offset={-50} duration={500}>
          <img
            src={logoImg}
            alt="/"
          />
        </Link>

        <p className="text-[10px] font-light text-center md:mb-6">
          Copyright ©2023 JORMAX PROJECTS - DESENVOLVIDO POR MUG.PT
        </p>
      </div>
      <div className=" w-full xs:w-[200px] h-auto flex flex-col">
        <div className="flex flex-col mx-auto my-auto w-full">
          <p className="text-xs leading-4 pt-2 font-light">
            Zona Industrial Rio Meão,Apt.459
          </p>
          <p className="text-xs leading-4 pt-0 font-light">
            4520-475 Santa Maria da Feira Portugal
          </p>
          <p className="text-xs leading-4 pt-2 font-light">(+351)256 783 591</p>
          <span className="text-[10px] mt-0 font-light">
            (chamada p/rede fixa nacional)
          </span>
          <p className="text-xs leading-3 pt-2 font-light">comercial@jormax.com</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
