import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";
//import workImg from "../assets/section5_img.png";

function Section05() {
  return (
    <div className="w-full bg-gray-300 h-screen flex flex-row m-0 p-0">
      <img
        className="w-full h-screen absolute object-cover"
        src="https://projects.jormax.com/images/Sections/section5_img.png"
        alt="/"
      />
      <div className="w-full h-screen overflow-hidden absolute m-0 p-0 transform-none">
        <div className="skew-x-30 absolute h-full bg-black/80 w-[700px] right-[-250px] sm:w-[800px] lg:w-[80%]"></div>
        <div className="skew-x-[30deg] absolute h-full bg-black/60 w-[200px] right-[-50px] sm:w-[300px] lg:w-[400px]"></div>
      </div>
      <div className="md:w-[1240px] w-sceen m-auto text-white flex relative">
        <div className="flex w-full xs:w-[400px] px-4 xs:w-[450px] md:w-[700px] my-auto ml-auto text-start flex-col relative">
          <p className=" text-4xl md:text-7xl uppercase p-2 font-poppins font-light">
            05.
          </p>
          <h1 className="text-4xl md:text-7xl font-poppins font-bold uppercase mt-1">
            impressão 3d
          </h1>
          <p className="text-sm leading-6 my-5 font-poppins font-light">
            Soluções inovadoras e personalizadas em impressão 3D. Transformamos
            ideias em realidade, criando objetos tridimensionais com uma
            precisão e detalhes impressionantes. A impressão 3D revolucionou a
            forma como projetamos, pensamos, prototipamos e fabricamos objetos.
            Somos capazes de imprimir em diversos materiais como plástico,
            metal, cerâmica, entre outros, o que nos permite atender às mais
            variadas necessidades e dos mais variados sectores, sejam eles de
            engenharia, medicina, arquitetura e design.
          </p>
          <button className=" text-xs w-[220px] uppercase px-6 py-5 mt-3 text-gray-300 font-poppins font-light">
            <Link className="z-999" to="budget" smooth={true} offset={-50} duration={500}>
              Pedir orçamento
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Section05;
