import React from 'react'
import bannerImg from '../assets/hero_img.png'
import logoImg from '../assets/LOGOTIPO_final.png'
import { IoArrowDownCircle } from "react-icons/io5";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Link, animateScroll as scroll } from "react-scroll";



function Hero() {
  return (
    <div name='home' className='w-full h-screen flex overflow-hidden'>
        <div className='w-full h-screen bg-gradient-to-b from-black/80 absolute'>
            <img className='h-full object-cover object-center mix-blend-overlay' src={bannerImg} alt="/" />
        </div>
        <div className='md:max-w-[1240px] max-w-full h-full mx-auto my-auto justify-center text-center text-white relative'>
            <img className=' w-60 m-auto left-0 right-0 sm:w-65 mt-[10%] absolute' src={logoImg} alt="/" />
            <div className='px-4 h-screen flex flex-col content-center justify-center relative'>
                <h1 className='text-2xl sm:text-3xl uppercase font-bold text-center font-poppins'>Mão de obra qualificada</h1>
                <p className='text-xl sm:text-2xl font-poppins font-normal uppercase'>para os seus projetos</p>
                <Link to="section1" smooth={true} offset={0} duration={500}><ChevronDownIcon className="flex w-10 p-2 mx-auto mt-6 text-gray-700 rounded-full bg-white hover:bg-slate-300 transition-colors"/></Link>
            </div>
        </div>
        
    </div>
  )
}

export default Hero