import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";
//import workImg from "../assets/section6_img.png";

function Section06() {
  return (
    <div className="w-full bg-gray-300 h-screen flex flex-row m-0 p-0">
      <img
        className="w-full h-screen absolute object-cover"
        src="https://projects.jormax.com/images/Sections/section6_img.png"
        alt="/"
      />
      <div className="w-full h-screen overflow-hidden absolute m-0 p-0 transform-none">
        <div className="-skew-x-30 absolute h-full bg-black/80 w-[700px] left-[-250px] sm:w-[800px] lg:w-[80%]"></div>
        <div className="-skew-x-30 absolute h-full bg-black/60 w-[200px] left-[-50px] sm:w-[300px] lg:w-[400px]"></div>
      </div>
      <div className="md:w-[1240px] w-sceen m-auto text-white flex relative">
        <div className="flex w-full xs:w-[400px] px-4 md:mx-0 xs:w-[450px] md:w-[780px] my-auto mr-auto text-start flex-col relative">
          <p className=" text-4xl md:text-7xl uppercase p-2 font-poppins font-light">
            06.
          </p>
          <h1 className="text-4xl md:text-7xl font-poppins font-bold uppercase mt-1">
            perfis de alumínio
          </h1>
          <p className="text-sm leading-6 my-5 font-poppins font-light">
            Somos especializados na fabricação e fornecimento de perfis de
            alumínio de alta qualidade. Com uma ampla variedade de formatos e
            tamanhos, oferecemos soluções versáteis e duradouras para atender a
            diferentes necessidades, principalmente no sector industrial, da
            arquitetura e construção. Os perfis de alumínio são abundantemente
            utilizados devido à sua leveza, resistência, durabilidade e
            capacidade de serem moldados em diferentes formas. Podem ser
            utilizados e aplicados numa infindável variedade de projetos, desde
            janelas e portas até estruturas para painéis solares e sistemas de
            construção modular.
          </p>
          <button className=" text-xs w-[220px] uppercase px-6 py-5 mt-3 text-gray-300 font-poppins font-light">
            <Link className="z-999" to="budget" smooth={true} offset={-50} duration={500}>
              Pedir orçamento
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Section06;
