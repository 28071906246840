import React from "react";
import { Link, animateScroll as scroll } from "react-scroll";
//import workImg from "../assets/section1_img.png";

function Section01() {
  return (
    <div
      name="section1"
      className="w-full bg-white h-screen flex flex-col overflow-hidden"
    >
      <img
        className="w-full h-screen absolute object-cover object-center"
        src="https://projects.jormax.com/images/Sections/section1_img.png"
        alt="/"
      />
      <div className="w-full h-screen overflow-hidden absolute m-0 p-0 transform-none">
        <div className="skew-x-30 absolute h-full bg-black/80 w-[700px] right-[-250px] sm:w-[800px] lg:w-[80%]"></div>
        <div className="skew-x-[30deg] absolute h-full bg-black/60 w-[200px] right-[-50px] sm:w-[300px] lg:w-[400px]"></div>
      </div>
      <div className="md:w-[1240px] h-auto w-sceen m-auto text-white flex relative">
        <div className="flex w-full xs:w-[400px] px-4 xs:w-[450px] md:w-[700px] my-auto ml-auto text-start flex-col relative">
          <p className=" text-4xl md:text-7xl uppercase p-2 font-poppins font-light ">
            01.
          </p>
          <h1 className="text-4xl md:text-7xl font-poppins font-bold uppercase mt-1">
            Soldadura
          </h1>
          <p className="text-sm leading-6 my-5 font-poppins font-light">
            A soldadura é uma técnica fundamental em diversos sectores
            industriais, da construção e fabricação até à manutenção e
            reparação. A Jormax possui conhecimentos sólidos e experiência
            abrangente numa variedade de processos de soldadura, incluindo a
            soldadura por elétrodo revestido, TIG, MIG/MAG, Arco Submerso,
            MIG/MAG cm Arame Tubular e Oxiacetileno. Trabalhamos com os mais
            altos padrões de qualidade, utilizando equipamentos modernos e
            materiais de topo. O nosso compromisso com a excelência permite-nos
            entregar resultados precisos e duradouros, garantindo a satisfação
            do cliente.
          </p>
          <button variant="contained" className="text-xs w-[220px] uppercase px-6 py-5 mt-3 text-gray-300 font-poppins font-light">
            <Link className="z-50" to="budget" smooth={true} offset={-50} duration={500}>
              Pedir orçamento
            </Link>
          </button>
          {/* <button className='bg-[#124270] text-[white] w-[200px] uppercase rounded-md font-medium my-6 mx-auto py-3 '>ver mais</button> */}
        </div>
      </div>
    </div>
  );
}

export default Section01;
